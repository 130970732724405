<template>
<!--  <nav>-->
<!--    <router-link to="/"></router-link>-->
<!--  </nav>-->
  <router-view/>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f6fff7;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #f6fff7;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
//body{
//  background-image: url("./assets/bogo.png");
//  background-size: 100%;
//  background-repeat: no-repeat;
//}
</style>
