import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/e/:cardPassword',
        name: 'home',
        component: HomeView,
        props: true
    },
    {
        path: '/defaultgood',
        name: 'defaultgood',
        component: () => import(/* webpackChunkName: "about" */ '@/views/DefaultGood.vue')
    },
    {
        path: '/cardgood',
        name: 'cardgood',
        component: () => import(/* webpackChunkName: "about" */ '@/views/CardGood.vue')
    },
    {
        path: '/ruixing',
        name: 'ruixing',
        component: () => import(/* webpackChunkName: "about" */ '@/views/RuiXing.vue')
    },
    {
        path: '/kudi',
        name: 'kudi',
        component: () => import(/* webpackChunkName: "about" */ '@/views/KuDi.vue')
    },
    {
        path: '/mdl',
        name: 'mdl',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Mdl.vue')
    },
    {
        path: '/fail',
        name: 'fail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/default/DefaultFail.vue')
    },
    {
        path: '/succ',
        name: 'succ',
        component: () => import(/* webpackChunkName: "about" */ '@/views/default/DefaultSucc.vue')
    },
    {
        path: '/ing',
        name: 'ing',
        component: () => import(/* webpackChunkName: "about" */ '@/views/default/DefaultIng.vue')
    },
    {
        path: '/wrong',
        name: 'wrong',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/default/DefaultWrong.vue')
    },
    {
        path: '/expire',
        name: 'expire',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/default/DefaultExpire.vue')
    },
    {
        path: '/nocards',
        name: 'nocards',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/result/NoCards.vue')
    },
    {
        path: '/kudisucc',
        name: 'kudisucc',
        component: () => import(/* webpackChunkName: "about" */ '@/views/kudi/KuDiSucc.vue')
    },
    {
        path: '/kudiing',
        name: 'kudiing',
        component: () => import(/* webpackChunkName: "about" */ '@/views/kudi/KuDiIng.vue')
    },
    {
        path: '/kudiwrong',
        name: 'kudiwrong',
        component: () => import(/* webpackChunkName: "about" */ '@/views/kudi/KuDiWrong.vue')
    },
    {
        path: '/kudiexpire',
        name: 'kudiexpire',
        component: () => import(/* webpackChunkName: "about" */ '@/views/kudi/KuDiExpire.vue')
    },

    {
        path: '/kudifail',
        name: 'kudifail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/kudi/KuDiFail.vue')
    },
    {
        path: '/mdlsucc',
        name: 'mdlsucc',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mdl/MdlSucc.vue')
    },
    {
        path: '/mdling',
        name: 'mdling',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mdl/MdlIng.vue')
    },
    {
        path: '/mdlwrong',
        name: 'mdlwrong',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mdl/MdlWrong.vue')
    },
    {
        path: '/mdlexpire',
        name: 'mdlexpire',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mdl/MdlExpire.vue')
    },
    {
        path: '/mdlfail',
        name: 'mdlfail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mdl/MdlFail.vue')
    },
    {
        path: '/cardsucc',
        name: 'cardsucc',
        component: () => import(/* webpackChunkName: "about" */ '@/views/card/CardSucc.vue')
    },
    {
        path: '/carding',
        name: 'carding',
        component: () => import(/* webpackChunkName: "about" */ '@/views/card/CardIng.vue')
    },
    {
        path: '/cardwrong',
        name: 'cardwrong',
        component: () => import(/* webpackChunkName: "about" */ '@/views/card/CardWrong.vue')
    },
    {
        path: '/cardexpire',
        name: 'cardexpire',
        component: () => import(/* webpackChunkName: "about" */ '@/views/card/CardExpire.vue')
    },

    {
        path: '/cardfail',
        name: 'cardfail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/card/CardFail.vue')
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404.vue')
    },
    {
        path: '/403',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403.vue')
    },
    {
        path: '/:pathMatch(.*)', redirect: '/404', hidden: true
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
