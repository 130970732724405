<template>
    <a-form ref="formRef" :model="model" id="home" >
        <a-row >
            <a-col :span="4"></a-col>
            <a-col :span="16">
                <a-form-item  name="account" :rules="[{ required: true,  pattern: /^1\d{10}$/, message: '请输入充值兑换账号!' }]">
                    <a-input :span="12"
                             size="large"
                             v-model:value="model.account"
                             type="text"
                             placeholder="请输入充值兑换账号（手机号充值）" @blur="handleBlurPhone">
                    </a-input>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <p>{{model.goodsName}}</p>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <p>有效期截止：{{model.expireDate.substring(0,10)}}</p>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <a-form-item>
                    <a-button type="primary" @click="onCheck" shape="round" size="large" danger>充值兑换</a-button>
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>

    <div>
        <h2>兑换说明</h2>
        <a-list size="small" :data-source="desc" id="desc">
            <template #renderItem="{ item }">
                <a-list-item>{{ item }}</a-list-item>
            </template>
        </a-list>
    </div>

</template>

<script>
// @ is an alias to /src
import { defineComponent,getCurrentInstance, reactive, ref } from 'vue';
import { useRouter } from "vue-router";

const desc = ['1、只支持手机号充值兑换，其他账号一律不支持','2、在点击充值兑换前，请仔细核对您输入账号是否正确，充值兑换成功后，不予退还','3、充值兑换成功，结果以页面显示为准，在有效期内使用','4、请在有效期截止期内完成充值兑换，逾期未使用视作自动作废','5、充值兑换地址只能使用1次，兑换成功后，再次使用无效']
export default defineComponent({
    setup(props) {
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const formRef = ref();
        const model = reactive({
            account: '',
            goodsName: '',
            expireDate: ''
        });
        const cardRoute = {
          saled:{
            hasOrderId: {
              resultSucc: "cardsucc",
              resultFail: "cardfail",
            },
            no: {
              no:"cardgood"
            }
          },
          noSaled: {
            hasOrderId: {
              resultSucc: "cardwrong",
              resultFail: "cardwrong",
            },
            no: {
              no:"cardgood"
            }
          },
          expire: {
            hasOrderId: {
              resultSucc: "cardexpire",
              resultFail: "cardexpire",
            },
            no: {
              no:"cardexpire"
            }
          },
        }
        const onCheck = async () => {
            debugger;
            try {
                const formData = await formRef.value.validateFields();
                formData.cardPassword = props.cardPassword;
                proxy.$http({
                    url: '/api/exchange',
                    method: 'POST',
                    params: formData
                }).then(res => {
                    debugger;
                    console.log(res);
                    if (res.data.success) {
                        router.push('/ing');
                    }else{
                        router.push('/fail');
                    }
                }).catch(err => {
                    console.log("catch"+err);
                })
            } catch (errorInfo) {
                console.log('Failed:', errorInfo);
            }
        };
        const handleBlurPhone = () => {
            validator.validate({ account: form.value.account }, (errors, fields) => {
                if (errors && fields.account) {
                    console.log(errors, fields);
                    console.log(fields.account[0].message);
                    return errors
                }
            })
        };

      return {
        model,
        // formItemLayout,
        // validateMobile,
        handleBlurPhone,
        onCheck,
        formRef,
        desc,
        cardRoute,
      };
    },
    props: ['cardPassword'],
    name: 'HomeView',
    components: {
        // HelloWorld
    },
    created() {
      const routeMap = {
        saled: {
          hasOrderId: {
            resultSucc: {
              ruixing: "succ",
              kudi: "kudisucc",
              mdl: "mdlsucc",
              default: "succ"
            },
            resultFail: {
              ruixing: "fail",
              kudi: "kudifail",
              mdl: "mdlfail",
              default: "fail"
            },
            no: {
              ruixing: "kudiing",
              kudi: "kudiing",
              mdl: "mdling",
              default: "ing"
            },

          },
          no: {
            no: {
              ruixing: "defaultgood",
              kudi: "kudi",
              mdl: "mdl",
              default: "defaultgood"
            }
          }
        },
        noSaled: {
          hasOrderId: {
            resultSucc: {
              ruixing: "wrong",
              kudi: "wrong",
              mdl: "wrong",
              default: "wrong"
            },
            resultFail: {
              ruixing: "wrong",
              kudi: "wrong",
              mdl: "wrong",
              default: "wrong"
            },
            no: {
              ruixing: "wrong",
              kudi: "wrong",
              mdl: "wrong",
              default: "wrong"
            }
          },
          no: {
            no: {
              ruixing: "defaultgood",
              kudi: "kudi",
              mdl: "mdl",
              default: "defaultgood"
            }
          }
        },
        expire: {
          hasOrderId: {
            resultSucc: {
              ruixing: "expire",
              kudi: "kudiexpire",
              mdl: "mdlexpire",
              default: "expire"
            },
            resultFail: {
              ruixing: "expire",
              kudi: "kudiexpire",
              mdl: "mdlexpire",
              default: "expire"
            },
          },
          no: {
            resultSucc: {
              ruixing: "expire",
              kudi: "kudiexpire",
              mdl: "mdlexpire",
              default: "expire"
            },
            resultFail: {
              ruixing: "expire",
              kudi: "kudiexpire",
              mdl: "mdlexpire",
              default: "expire"
            },
            no: {
              ruixing: "expire",
              kudi: "kudiexpire",
              mdl: "mdlexpire",
              default: "expire"
            }
          }
        }
      };

      this.$http.post("/api/cardLinkInfo", {cardPassword: this.cardPassword}).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          let saleType, orderIdType, resultType, goodType, rechargeType;
          if (res.data.content == null) {
            this.$router.push('/wrong');
          } else {
            if (res.data.content.saledType == "0") {
              saleType = "noSaled";
            } else if (res.data.content.saledType == "1") {
              saleType = "saled";
            } else if (res.data.content.saledType == "2") {
              saleType = "noSaled";
            } else {
              saleType = "expire";
            }

            if (res.data.content.orderId == null || res.data.content.orderId == '') {
              orderIdType = "no";
            } else {
              orderIdType = "hasOrderId";
            }

            if (res.data.content.result == "1") {
              resultType = "resultSucc";
            } else if (res.data.content.result == "2") {
              if (new Date().getTime() - new Date(res.data.content.updateTime).getTime() < 30 * 60 * 1000) {
                resultType = "resultFail";
              } else {
                orderIdType = "no";
                resultType = "no";
              }
            } else {
              resultType = "no";
            }
            console.log(saleType)
            console.log(orderIdType)
            console.log(resultType)
            if(res.data.content.rechargeType==2){

              let cardToRoute = this.cardRoute[saleType][orderIdType][resultType];
              this.$router.push({name: cardToRoute, params: res.data.content});
              return ;
            }
            console.log(res.data.content.name)
            if (res.data.content.name.indexOf("瑞幸咖啡") >= 0) {
              goodType = "ruixing";
            } else if (res.data.content.name.indexOf("库迪") >= 0) {
              goodType = "kudi";
            } else if (res.data.content.name.indexOf("麦当劳") >= 0) {
              goodType = "mdl";
            } else {
              goodType = "default";
            }
            console.log(goodType)
            let toRoute = routeMap[saleType][orderIdType][resultType][goodType];
            this.$router.push({name: toRoute, params: res.data.content});
          }

        } else {
          this.$router.push('/403');
        }
      }).catch(err => {
        console.log("catch"+err);
        this.$router.push('/404');
      })
    },
  methods: {}
})
</script>
<style lang="less" scoped>
    .ant-form {
        color: #f6fff7;
        font-size: 18px;
        margin-top: 50px;
    }
    h2 {
        margin-top: 10%;
        color: #fd3434;
    }

    .desc{
        text-align: left;
    }
    .ant-list {
        color: #999999;
        text-align: left;
    }
</style>
