import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Axios from './plugnis/axios'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

// 创建一个App
const app = createApp(App)
// 挂载Axios
app.use(Axios)
app.use(Antd)
// 挂载路由
app.use(router)
// 绑定DOM
app.mount('#app')

